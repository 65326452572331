import { useEffect, useState } from "react";

function Success() {

    const [response, setResponse] = useState({
        "status": "PENDING",
    })

    const baseUrl = "https://script.google.com/macros/s/AKfycbzh73-LRplygaDDHNVPpQKJPZGznhFTVtUIBO7tgMjXj1Hjb7P9AX3QO-V4Hs7Jey4b6w/exec";

    const urlParams = new URLSearchParams(window.location.search);
    const referenceId = urlParams.get('TXNID');

    const sendRefId = async () => {
        await fetch(baseUrl + "?referenceId=" + referenceId, {
            redirect: "follow",
            method: "GET",
          }).then(async (data) => {
            let resp = await data.json();
            setResponse(resp);
        }).catch(err => {
            setResponse({status: "FAILED"})
        })

    }

    useEffect(() => {
        sendRefId()
    }, []);

    const getStatusHtml = () => {
        if (response.status === "PENDING") {

            return (
                <h1 className="text-[24px] font-medium text-blue-500">Pending...</h1>
                )
        }else if(response.status === "SUCCESS"){
            return (
                <div className="items-center justify-center flex flex-col gap-2 px-8 py-4 rounded-2xl bg-green-100 shadow-md shadow-gray-400">
                <h1 className="block text-[24px] font-medium text-green-700 ">Success!</h1>
                <img src={response.img_as_b64} className="w-48 h-48"/>
                <h1 className="font-semibold text-[24px]">{response.team_name}</h1>
                <h1 className="font-semibold text-[16px] text-green-700">{response.event_name}</h1>
                </div>
            )
        }else{
            return (
                <h1 className=" text-[24px] font-medium text-red-600 bg-red-100 px-8 py-4 rounded-xl">Error!</h1>
            )
        }
    }

    return (
        <div className="h-screen flex justify-center items-center">
            { getStatusHtml() }
        </div >
    );
}

export default Success;
