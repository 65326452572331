import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Success from './Success';
import Fail from './Fail';
import NotFound from "./NotFound"
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  { path: "/payment/success", element: <Success />},
  { path: "/payment/failure", element: <Fail />},
  { path: '*', element: <NotFound /> }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
