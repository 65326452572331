import { useState } from "react";
import logo from './logo.png';
function App() {

  // const baseUrl = process.env.REACT_APP_TOKEN_URL;
  const baseUrl = "https://script.google.com/macros/s/AKfycbysLA6KRnE7tzFHr6eAae5Qd7UGqbKf2YEJByiRysXiB_STFXztDP6OLxKT_u30agehZw/exec"
  const cipsLogin = "https://login.connectips.com/connectipswebgw/loginpage"

  const [formData, setFormData] = useState({
    event: "project-registration"
  });

  const [memberData, setMemeberData] = useState({
    mem1: {
      "name": "",
      "email": "",
      "number": ""
    },
    mem2: {
      "name": "",
      "email": "",
      "number": ""
    },
    mem3: {
      "name": "",
      "email": "",
      "number": ""
    },
    mem4: {
      "name": "",
      "email": "",
      "number": ""
    },
  })

  const onEventChange = (e) => {
    setFormData({ ...formData, event: e.target.value });
  }

  const getTeamMembers = (e) => {
    return {
      team_members: memberData.mem1.name + "," + memberData.mem2.name + "," + memberData.mem3.name + "," + memberData.mem4.name,
      emails: memberData.mem1.email + "," + memberData.mem2.email + "," + memberData.mem3.email + "," + memberData.mem4.email,
      contact_nos: memberData.mem1.number + "," + memberData.mem2.number + "," + memberData.mem3.number + "," + memberData.mem4.number,
    }
  }

  const returnInput = (label, name, type, required, value, updateFunction) => {
    value = value ?? formData[name];
    updateFunction = updateFunction ?? onInputChange;
    return (
      <>
        <div className="mb-4">
          <label className="block text-gray-700 text-lg font-bold mb-2" for={name}>
            {label}
          </label>
          <input
            required={required ? "required" : ""}
            value={value}
            onChange={updateFunction}
            className="shadow appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name={name} type={type} placeholder={label} />
        </div>
      </>
    )
  };

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const onMemberValueChange = (e) => {
    const newData = { ...memberData };
    newData[e.target.name.substring(0, 4)][e.target.name.substring(4)] = e.target.value;
    setMemeberData({ ...newData });
  }

  const onFormSubmit = async (e) => {
    e.preventDefault();
    console.log(formData, memberData);
    const teamMem = getTeamMembers();
    const payloadData = JSON.stringify({ ...formData, ...teamMem })
    console.log(payloadData);
    const resp = await fetch(baseUrl, {
      redirect: "follow",
      method: "POST",
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
      },
      body: payloadData,
    })

    const responseData = await resp.json()
    console.log(responseData);

    const bodyFormData = new FormData();

    for (var key in responseData) {
      document.getElementById(key).value = responseData[key];
      // bodyFormData.append(key, responseData[key]);
    }

    document.getElementById("submitForm").submit();

    // const redirectResponse = await axios({
    //   redirect: "follow",
    //   method: "post",
    //   url: cipsLogin,
    //   data: bodyFormData,
    //   headers: { "Content-Type": "multipart/form-data" },
    // })

    // console.log(redirectResponse);

  }

  return (
      <div className="flex justify-center items-center bg-[#b375d3]   bg-opacity-80">
        <div className="hidden">
          <form action={cipsLogin} method="post" id="submitForm">
            <input type="text" name="MERCHANTID" id="MERCHANTID" value="2023" />
            <input type="text" name="APPID" id="APPID" value="MER-2023-APP-1" />
            <input type="text" name="APPNAME" id="APPNAME" value="Locus" />
            <input type="text" name="TXNID" id="TXNID" value="txn123" />
            <input type="text" name="TXNDATE" id="TXNDATE" value="15-03-2022" />
            <input type="text" name="TXNCRNCY" id="TXNCRNCY" value="NPR" />
            <input type="text" name="TXNAMT" id="TXNAMT" value="500" />
            <input type="text" name="REFERENCEID" id="REFERENCEID" value="REF-001" />
            <input type="text" name="REMARKS" id="REMARKS" value="" />
            <input type="text" name="PARTICULARS" id="PARTICULARS" value="" />
            <input type="text" name="TOKEN" id="TOKEN" value="" />
            <input type="submit" value="Submit" id="Submit" /> </form>
        </div>
        <div className="flex-[0.9] md:flex-[0.8] lg:flex-[0.7] mt-8">
          <form className="bg-white shadow-lg shadow-[#282827] rounded-xl  px-8 pt-6 pb-8 mb-4" onSubmit={onFormSubmit}>
            <div className="flex justify-center">
              <img src={logo} alt={logo} />
            </div>
            <div className="mb-2 bg-slate-200 px-4 py-2 rounded-lg">
              {returnInput("Team Name", "team_name", "text", true)}
            </div>
            <p className="px-4 py-2 font-bold text-gray-700 text-lg">
              Team Members
            </p>
            <div className="flex flex-col justify-between">
              <div className="flex flex-col md:flex-row gap-2">
              {
                ["mem1", "mem2"].map((member, i) => {
                  return <div className="w-full bg-slate-200 px-4 py-2 rounded-lg my-2">
                    <p className="font-medium text-gray-700"> Member {i + 1}</p>
                    {returnInput("Name", member + "name", "text", i === 0, memberData[member].name, onMemberValueChange)}
                    {returnInput("Email", member + "email", "email", i === 0, memberData[member].email, onMemberValueChange)}
                    {returnInput("Phone", member + "number", "number", i === 0, memberData[member].number, onMemberValueChange)}
                  </div>
                })
              }
              </div>
              <div className="flex flex-col md:flex-row gap-2 justify-between">              {
                ["mem3", "mem4"].map((member, i) => {
                  return <div className="w-full bg-slate-200 px-4 py-2 rounded-lg my-2">
                    <p className="font-medium text-gray-700"> Member {i + 3}</p>
                    {returnInput("Name", member + "name", "text", false, memberData[member].name, onMemberValueChange)}
                    {returnInput("Email", member + "email", "email", false, memberData[member].email, onMemberValueChange)}
                    {returnInput("Phone", member + "number", "number", false, memberData[member].number, onMemberValueChange)}
                  </div>
                })
              }
              </div>
            </div>
            <div className=" bg-slate-200 px-4 pt-2 pb-4 my-4 rounded-lg">
              <label className="text-lg font-bold  text-gray-700" for="event">Event</label>
              <select  name="event" onChange={onEventChange} value={formData.event} className="shadow appearance-none border rounded w-full  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                <option value="project-registration" selected>Project Registration</option>
                {/* <option value="robowarz">Robowarz</option> */}
              </select>
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline shadow-[#282827] shadow-sm"
                type="submit">
                Submit
              </button>
            </div>
          </form>
        </div >
      </div >
  );
}

export default App;
